 #download {
   display: flex;
   justify-content: center;
   text-align: left;
   margin-top: 100px;
   margin-bottom: 100px;
 }

 .download {
   &__title {
     color: var(--color-secondary);
     font-size: 3rem;
     font-weight: 700;
     line-height: 150%;
   }

   &__info {
     width: 50%;
   }

   &__text {
     color: #201C26;
     font-weight: 500;
     line-height: 150%;
     margin: 30px 0;
     font-weight: bold;
   }

   &__image-mobile {
     display: none;
   }

   &__button {
     margin-right: 24px;
   }


 }

 @media screen and (max-width: 1048px) {
   .download {
     &__info {
       width: 100%;
     }

     &__title,
     &__text {
       text-align: center;
       margin: 60px 0 60px 0;
       padding: 0 10px 0 10px;
     }

     &__app {
       display: flex;
       flex-direction: column;
       text-align: center;
     }

     &__image {
       display: none;
     }

     &__image-mobile {
       display: flex;
       justify-content: center;
       align-items: center;
       width: 100%;
       margin: 60px 0 60px 0;
     }

     &__button {
       margin-bottom: 24px;
       margin-right: 0;
     }
   }
 }