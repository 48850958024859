.bg-header {
  display: flex;
  flex-direction: row;
  background-image: url(../assets/images/bg-header.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: auto;
  height: auto;
  justify-items: center;

}

.header {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 6rem 0;
  width: 100%;
}


.telefone-image {
  display: block;
  max-width: 100%;
  width: 408px;
  height:588px;
  position: relative;
  bottom: 89px;

}

.header-info {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 36px;

  & h1 {
    max-width: 703px;

    line-height: 62px;
    // word-wrap: break-word;

    color: var(--font-white);
  }

  & p {
    max-width: 703px;

    font-weight: 500;
    font-size: 1.25rem;
    line-height: 150%;

    color: var(--font-white);
  }
}

@media screen and (max-width: 506px) {

  .header {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 15px;
  }

  .header-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
      max-width: 358px;
      text-align: center;
      line-height: 62px;
      // word-wrap: break-word;
      font-weight: 700;
      font-size: 24px;
      line-height: 150%;
      color: var(--font-white);
    }

    & p {
      max-width: 300px;
      text-align: center;
      font-weight: 500;
      font-size: 1.25rem;
      line-height: 150%;
      font-size: 14px;

      color: var(--font-white);
    }

  }

  .telefone-image {
    align-self: center;
  }

  .telefone-image {
    width: 208px;
    height:276px;
    position: static;
  }


}