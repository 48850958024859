#blog {
  justify-content: center;
  text-align: left;
}

.blog-card-image {
  width: 100%;
}

.blog {
  &__respansive-card {
    display: flex;
    justify-content: space-around;
    margin-top: 4rem;
    flex-wrap: wrap;
    max-width: 1200px;
    gap: 22px;
    align-self: center;
    padding: 0 25px;
  }


  &__title {
    color: var(--color-secondary);
    line-height: 150%;
    font-weight: 700;
    width: 100%;
    text-align: center;
    margin-top: 120px;
  }


  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 368px;
    height: 432px;
    margin-top: 80px;

    background: var(--color-white-bg);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;

    &__body {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      padding: 20px;
    }

    &__title {
      font-weight: 600;
      line-height: 24px;
      color: var(--color-primary);
      text-align: justify;
    }

    &__text {
      line-height: 19px;
      text-align: justify;
    }

    &__border {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
    }

    &__link {
      padding: 8px 16px;
      border: 1px solid var(--color-secondary);
      border-radius: 8px;

      font-weight: 700;
      line-height: 21px;
      color: var(--color-secondary);
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 120px;

    width: 190px;
    height: 59px;

    background: var(--color-secondary);
    border-radius: 8px;

    text-align: center;
    color: var(--font-white);

    &__centalize {
      display: flex;
      width: 100%;
      align-items: center;
      margin-top: 80px;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 480px) {
  .blog__card {
    width: 320px;
  }
}
