.navbar {
  display: flex;
  position: fixed;
  height: 64px;
  width: 100%;
  background-color: var(--color-white-bg);
  z-index: 100;
  border-bottom: 1px solid var(--color-gray-bg);

  & .container {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__check {
    display: none;
  }

  &__check:checked ~ .menu-list-dropdown {
    display: block;
  }

  &__menu {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("../assets/svg/icon-menu.svg");
    background-repeat: no-repeat;
    background-position: center;
  }

  &__image {
    padding: 1rem;
  }

  &__logo {
    height: 3rem;
  }

  & li {
    font-size: 1rem;
  }

  & li a {
    display: block;
    padding: 1rem;

    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
  }
}

.navbar_back::after {
  display: block;
  content: "";
  width: 100%;
  height: 64px;
}

.menu-list-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  width: 100%;
  height: 60vh;
  padding: 0 10px;

  background: rgba($color: #fff, $alpha: 0.95);
}

.menu-list-bar {
  display: none;
  width: auto;
  height: auto;
  margin: 0 1rem;

  & li {
    display: inline-block;
  }

  & li a {
    padding: 1.6rem 1rem;
  }
}

// @media screen and (min-width: 640px) {
@media screen and (min-width: 800px) {
  .navbar {
    height: 72px;

    &__menu {
      display: none;
    }

    &__logo {
      height: 4.5rem;
    }

    &__check:checked ~ .menu-list-dropdown {
      display: none;
    }
  }

  .menu-list-bar {
    display: block;
  }
}

.navbar__menu.navbar__image {
  margin-right: .5rem;
}
