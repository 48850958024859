#obrigado-header {
  background: url(../assets/images/Background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#logo-obrigado {
  position: absolute;
  margin: 2rem;
  max-width: 15rem;
  height: 2.5rem;;
}

.header-description-obrigado {
  margin: 0;
  text-align: center;
  max-width: 500px;
  color: #fff;
  font-family: 'Open-sans';
  font-weight: 600;
  font-size: 20px;
  width: 500px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 40px;
}

#main-navbar-obrigado {
  --navbar-background: transparent;
  width: 100%;
  display: flex;
  transition: .4s;
  padding: 3rem;
  position: fixed;
  min-height: 74px;
  text-align: left;
  background: var(--navbar-background);
  align-items: center;
  gap: 1rem;
  z-index: 1000;
}

.header-title-obrigado {
  font-size: 28px;
  margin: 0 16px;
  text-align: center;
  font-family: 'Kanit';
  line-height: 44.4px;
  font-weight: 700;
  font-size: 50px;
  color: #FFF;
}

.button-home {
  background-color: var(--color-secondary);
  color: var(--font-white);
  font-weight: 600;
  font-size: 1rem;
  line-height: 150%;
  padding: 10px;
  border-radius: 8px;
  margin: 10px;
}
