#feedback {
  justify-content: center;
  text-align: left;
}

.feedback-bg {
  display: flex;
  flex-direction: column;
  background-image: url(../assets/images/image-feedback.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: auto;
  height: auto;
  padding: 120px;

  & h2 {
    margin: 1rem;
    color: var(--font-white);
    align-self: center;
    line-height: 150%;
  }
}

.feedback-line-cards {
  display: flex;
  justify-content: space-around;
  margin-top: 4rem;
  flex-wrap: wrap;
  gap: 40px;
  max-width: 1200px;
  align-self: center;
}

.feedback-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 368px;
  height: 432px;
  margin-top: 60px;

  background: var(--color-white-bg);
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  &__image {
    position: absolute;
    top: -50px;

    width: 91px;
    height: 91px;
    border-radius: 45.5px;
  }

  &__vetor {
    margin-top: 50px;
  }

  &__text {
    text-align: justify;
    color: #3f3f3f;
    max-width: 280px;

    font-weight: 500;
    line-height: 150%;
    // word-wrap: break-word;
  }

  &__name {
    text-align: center;
    color: #2e1742;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 150%;
  }

  &__occupation {
    color: #2e1742;
    text-align: center;

    font-weight: 400;
    font-size: 1.25rem;
    line-height: 150%;
  }
}

@media screen and (max-width: 480px) {
  .feedback-card {
    width: 300px
  }

  .feedback-bg h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    /* or 36px */
    text-align: center;
    width: 329px;
  }
}