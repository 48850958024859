.bg-faq {
  display: flex;
  align-items: center;
  min-height: 874px;
  background-color: #fafafa;
}

.faq-title {
  text-align: center;
  padding: 1rem;
  color: #582597;
}

#faq>div {
  width: 100%;
}

.faq-body>div {
  padding: 2rem;
  height: 100%;
}

div.faq-view {
  // display: flex;
  display: none;
  flex-direction: column;
  gap: 24px;
  padding: 1rem;

  & h3 {
    color: #000;
    text-align: start;
  }
}

.active .faq-view {
  display: flex;
}

div.faq-item {
  position: relative;
  padding-top: 24px;
  // padding-bottom: 24px;
  text-align: start;
  cursor: pointer;

  &>p {
    width: 90%;
  }

  &::after {
    content: "";
    display: block;
    margin-top: 24px;
    width: 100%;
  }

  &:nth-last-child(n + 2)::after {
    height: 2px;
    background-color: #d9d9d9;
  }

  &>span.arrow {
    display: block;
    width: 15px;
    height: 20px;
    position: absolute;
    top: 27px;
    right: 0;
    margin-left: 30px;
    background-image: url("../assets/svg/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.faq-list.active .faq-item>p {
  font-weight: bold;
  color: #000;
}

.faq-list.active .faq-item>span.arrow {
  background-image: url("../assets/svg/arrow-top.svg");
}


// @media screen and (max-width: 690px) {
// @media screen and (max-width: 1120px) {
//   .faq-body {
//     max-width: 800px;
//     margin-left: auto;
//     margin-right: auto;

//     // & > div {
//     //   width: 100%;
//     // }
//   }
// }