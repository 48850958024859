@import "navbar";
@import "header";
@import "about";
@import "feedback";
@import "faq";
@import "download";
@import "blog";
@import "footer";
@import "contatos";
@import "obrigado";

:root {
  --color-primary: #582597;
  --color-secondary: #B06300;
  --color-gray: #60596b;
  --color-white-bg: #ffffff;
  --color-gray-bg: #e5e5e5;
  --font-white: #fff;
}

* {
  text-decoration: none;
  box-sizing: border-box;
}

body {
  background-color: var(--color-white-bg);
  font-family: "SF Pro Display", Arial, sans-serif;
  font-style: normal;
  font-size: 1.125rem;
}

h1 {
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 150%;
}

h2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 150%;
}

h3 {

  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 24px;

  text-align: center;

  color: var(--color-secondary);
}

p {
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.full-screen {
  width: 100%;
  min-height: 100vh;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.btn-secondary {
  display: inline-block;
  padding: 0.875rem 1.25rem !important;
  background-color: var(--color-secondary);
  border-radius: 8px;
  color: var(--font-white) !important;

  font-size: 1rem;
  font-weight: 700;
  line-height: 150%;

  text-align: justify;
}

.flex {
  display: flex;
}

.flex-justify {
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.block {
  display: block;
}

/* begin alert popup */


.toast {
  position: fixed;
  right: 0;
  top: 60px;
  margin: 30px;
  overflow: hidden;
  
  height: auto;
  width: 250px;
  padding: 17px;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(56, 56, 56, 0,);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  }
  
  .toast  h2 {
    color: var(--font-white);
    font-weight: bold;
    font-size: 18px;
  }
  
  .toast p {
    color: var(--font-white);
    font-size: 14px;
  }

.pup-error {
    background-color: #FF0000;
  }

.pup-success {
  background-color: #00a000;
}

/* end alert popup */
/* begin cookies */
.CookieMessage {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: none;
  align-items: end;
  justify-content: center;
}

.CookieMessage-content {
  display: flex;
  max-width: 100%;
  max-height: 180px;
  background-color: var(--color-primary);
  padding: 2rem;
  border-radius: 8px;
  color: var(--color-white-bg);
  margin-bottom: 20px;
  gap: 40px;
  align-items: center;
  height: 72px;
}

.CookieMessage-content a{
  color: white;
  font-weight: 700;
}

.CookieMessage-button {
  background: var(--color-secondary);
  display: flex;
  text-decoration: none;
  padding: 12px 14px;
  border-radius: 15px;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
  
}

.CookieMessage-button:hover{
  background: #db7c01;
}

.link-cookie {
  text-decoration: underline;
}
// /* end cookies */


@media screen and (max-width: 480px) {

  h1 {
    font-size: 3rem;
  }

}
@media screen and (max-width: 533px) {
  .CookieMessage-content {
    height: 128px;
  }
}

@media screen and (max-width: 374px) {
  .CookieMessage-content {
    height: 180px;
  }
}
