#about {
  justify-content: center;
  text-align: left;
  margin-left: auto;
  margin-right: auto;

  & h2 {
    color: var(--color-secondary);
  }
}

.paddin-card-about {
  display: flex;
  align-items: center;
  flex-direction: column;

}
.about-card-image {
  display: block;
}

.about {
  &__title-top {
    margin: 46px 0;
    width: 50%;
    align-self: center;
    margin-left: auto;
    margin-right: auto;

    line-height: 150%;
  }

  &__text-top {
    width: 580px;

    font-weight: 500;
    line-height: 150%;
    text-justify: initial;
  }

  &__top {
    flex-wrap: wrap;
    max-width: 1200px;
    align-items: center;
    justify-content: center;
  }

  &__button {
    display: flex;
    margin-top: 71px;

  }
}

@media screen and (max-width: 1155px) {
  .about__top {
    text-align: center;
    width: 70%;
  }
}

@media screen and (max-width: 1013px) {
  .about__button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 60px;
  }
}

.about-card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  box-sizing: border-box;
  width: 250px;
  height: 250px;

  border: 2px solid #beb5c9;
  border-radius: 8px;

  &__text {
    width: 288px;
    padding: 18px 0;
    font-weight: 500;
    line-height: 150%;

    &__Bold{ 
      width: 288px;
      padding: 18px 0;
  
      font-weight: bold;
      line-height: 150%;}
  }

  &__title {
    width: 100%;
    text-align: center;
    padding-top: 32px;

    font-weight: 700;
    font-size: 1.25rem;
    line-height: 24px;
  }
}

.card-about {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  align-self: center;
  margin-bottom: 120px;
  margin-left: auto;
  margin-right: auto;

  & h2 {
    padding-bottom: 20px;
  }

  &__image {
    display: block;
    max-width: 100%;
    width: 487px;
    height: 475px;
  }

  &__info {
    width: 50%;
  }

  &__more-example {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 1200px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 120px;
  }
}

.text-more-example {
  padding-top: 25px;

  font-weight: 500;
  line-height: 150%;
}

.about-card-revert {
  flex-direction: row-reverse;
}

.image-card-about {
  display: flex;
}

@media screen and (max-width: 1013px) {
  .about-card-revert {
    flex-direction: row;
  }

  .text-more-example {
    text-align: center;
  }

  #about h2 {

    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }

  .card-about__info {
    width: 70%;
    margin-bottom: 60px;
  }

  .card-about__image {
    align-self: center;
  }

  .image-card-about {
    width: 100%;
    justify-content: center;
  }
}

@media screen and (max-width: 480px) {
  .about__title-top {

    width: 100%;

  }

  .about-card {
    margin: .5rem 1rem;
  }
}