#contact-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contatos {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 6rem 0;

  &__padding {
    padding-bottom: 96px;
  }

  &__bg {
    display: flex;
    flex-direction: row;
    background: url(../assets/images/Contatos-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: auto;
    height: 1123px;
    justify-items: center;
    align-items: center;
  }

  &__formulario-bg {
    padding: 2rem;
    padding-bottom: 4rem;
    max-width: 840px;
    min-height: 440px;
    background: var(--color-white-bg);
    border-radius: 8px;
  }
}

.cantact-title{
  color: var(--color-white-bg);
  margin-top: 200px;
  margin-bottom:100px; 
}
textarea{
  font-weight: 700 !important;
  font-size: 20px !important;
}
.form-box { 
  font-style: normal;
  font-weight: 500;
  color: #201C26;

    & input, select, textarea {
      min-height: 40px;
      width: 100%;
      border: 1px solid #575757;
      border-radius: 8px;

      color:#582597;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-align: start;
      
      margin-top: 10px;
      resize: none;
      padding: .5rem;
    }

    & select {
      -moz-appearance:none !important;
      -webkit-appearance: none !important; 
      appearance: none !important;
      background-image: url(../assets/svg/select-arrow.svg);
      background-color: var(--color-white-bg);
      background-repeat: no-repeat;
      background-position: right 1rem center;
    }
    & .form-error-message {
      min-height: 40px;
      width: 100%;
      border: 1px solid red;
      border-radius: 8px;

      color:#582597;
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      text-align: start;
      
      margin-top: 10px;
      resize: none;
      padding: .5rem;
    }
}

// begin form error


.error-input {
  display: none;
  color: red;

  &.active {
    display: inline;
  }
}
// end form error

.required-obs {
  color: red;
}

.form-contact {
  display: flex;
  flex-wrap: wrap;
  gap: 26px;
  
  & > div {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    width: 48%;
    gap: 22px;
  }
}

@media screen and (max-width: 785px) {
  .form-contact {
    flex-direction: column;
      & > div {
        margin: 0;
        width: 100%;
      }
  }
  .cantact-title {
    margin-bottom: 0;
    font-size: 1.5rem;
  }
}

.button-send{
  height: 40px;
  background: #582597;
  border-radius: 8px;
  border: 1px solid #575757;
  
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 30px;
}

@media screen and (max-width: 785px) {
  .contatos__formulario-bg{
    margin: 1rem;
    border: 1px solid #575757;
  }
}