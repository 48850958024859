#footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #230F3C;

}
.footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #230F3C;
}

.footer-logo {
  max-width: 81px;
  max-height: 40px;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 230px;
}

.footer-container__text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: var(--font-white);
}

.footer-container {
  display: flex;
  width: 100%;
  padding: 30px 16px;

  & p {
    font-weight: 400;
    font-size: 14px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: var(--font-white);
  }

  & b {
    font-weight: 600;
    font-size: 14px;
  }

  &__contatos {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    gap: 5px;
    justify-content: space-between;
  }
}

.footer-link {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 150px;
  color: var(--font-white);

  &>a {
    width: 100%;
    text-align: end;
  }

  &__respansive {
    display: none;
  }
}

.footer-copyright {
  padding: 10px;
  width: 100%;

  & p {
    font-size: 14px;
    color: var(--font-white);
    padding: 1rem;
    padding-bottom: .5rem;
    text-align: center;
  }

  &::before {
    display: flex;
    content: '';
    height: 1px;
    background-color: var(--font-white);
  }
}

.footer-contact__social-media {
  display: flex; 
  // width: 132px;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .footer-link {
    display: none;

    &__respansive {
      display: flex;
      flex-direction: column;
      padding-top: 32px;
      gap: 24px;
      color: var(--font-white);
    }

    &>a {
      width: 100%;
      text-align: start;
    }
  }

  .flex {
    flex-direction: column;
  }
}